import React  from 'react';
import { useState } from "react";
import{
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion'
import "react-accessible-accordion/dist/fancy-example.css";
import {MdOutlineArrowDropDown} from 'react-icons/md'
import './Value.css'
import data from '../../utils/accordion'

const Value = () => {
    const [className , setClassName]= useState(null)
  return (
    <section className="v-wrapper">
    <div className="paddings innerWidth flexCenter v-container">
     {/*left side */}
     <div className="v-left">
        <div className="image-container">
            <img src="./value.png" alt="" />
        </div>
        </div>      
          {/* right side */}
          <div className="flexColStart v-right">
            <span className="orangeText">Our Values</span>
            <span className="primaryText">Value We Give to You</span>
            <span className="secondryText">
             We always ready to help by providing services for you.
              <br/>
             We beleive a good blace to live can make life better 
            </span>

            <Accordion
            className="accordion"
            allowMultipleExpanded={false}
            preExpanded={[0]}
            >
             {
                data.map((item, i) =>{
                   // const [className , setClassName] = useState(null)
                    return(
                      <AccordionItem className={'accordionItem ${className}'} key={i} uuid={i}>
                        <AccordionItemHeading>
                          <AccordionItemButton className="flexCenter accordionButton">
                             <AccordionItemHeading>
                                <AccordionItemButton className="flexCenter accordionButton">
                                    <AccordionItemState>
                                        {
                                            ({expanded}) =>
                                            expanded 
                                            ? setClassName("expanded")
                                            : setClassName("collapsed")
                                        }
                                    </AccordionItemState>
                                </AccordionItemButton>
                             </AccordionItemHeading>
                           
                            <div className="flexCenter icon">{item.icon}</div>
                            <span className="primaryText">
                                {item.heading}
                            </span>
                            <div className="flexCenter icon">
                                <MdOutlineArrowDropDown size={20}/>
                            </div>
                          </AccordionItemButton>

                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className="secondryText">{item.detail}</p>
                        </AccordionItemPanel>
                      </AccordionItem>

                    )

                })
             }

            </Accordion>
          </div>
   
    </div>

    </section>
  )
}

export default Value
