import React from 'react'
import './GetStarted.css'

const GetStarted = () => {
  return (
    
    <section className="g-wrapper">
        <div className="paddings innerWidth g-container">
            <div className="flexColCenter inner-container">
                <span className="primaryText">Get Started with Homyz</span>
                
                <span className="secondaryText">Subscribe and find super attractive price
                    <br/>
                    Find Your residence soon
                </span>
                <button className="button">
                   <a href="kali@gmail.com">Get Started</a> 
                    
                    </button>
            </div>
        </div>
       <div>


       </div>

    </section>


    
      
    
  )
}

export default GetStarted
